import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ssh config`}</strong>{` -- configures ssh to be used with certificates`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ssh config
[--team=<name>] [--team-authority=<sub-domain>] [--host]
[--set=<key=value>] [--set-file=<file>] [--dry-run] [--roots]
[--federation] [--force] [--offline] [--ca-config=<file>]
[--ca-url=<uri>] [--root=<file>] [--context=<name>]
[--authority=<name>] [--profile=<name>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ssh config`}</strong>{` configures SSH to be used with certificates. It also supports
flags to inspect the root certificates used to sign the certificates.`}</p>
    <p>{`This command uses the templates defined in step-certificates to set up user and
hosts environments.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--host`}</strong>{`
Configures a SSH server instead of a client.`}</p>
    <p><strong parentName="p">{`--team`}</strong>{`=`}<inlineCode parentName="p">{`ID`}</inlineCode>{`
The team `}<inlineCode parentName="p">{`ID`}</inlineCode>{` used to bootstrap the environment.`}</p>
    <p><strong parentName="p">{`--team-authority`}</strong>{`=`}<inlineCode parentName="p">{`sub-domain`}</inlineCode>{`
The `}<inlineCode parentName="p">{`sub-domain`}</inlineCode>{` of the certificate authority to bootstrap. E.g., for an authority with
domain name 'certs.example-team.ca.smallstep.com' the value would be 'certs'.`}</p>
    <p><strong parentName="p">{`--team-url`}</strong>{`=`}<inlineCode parentName="p">{`url`}</inlineCode>{`
The `}<inlineCode parentName="p">{`url`}</inlineCode>{` step queries to retrieve initial team configuration. Only used with
the `}<strong parentName="p">{`--team`}</strong>{` option. If the url contains `}<inlineCode parentName="p">{`<>`}</inlineCode>{` placeholders, they are replaced with the team ID.
Replacing the authority-id section of the url is not supported with placeholders.`}</p>
    <p><strong parentName="p">{`--roots`}</strong>{`
Prints the public keys used to verify user or host certificates.`}</p>
    <p><strong parentName="p">{`--federation`}</strong>{`
Prints all the public keys in the federation. These keys are used to verify
user or host certificates`}</p>
    <p><strong parentName="p">{`--set`}</strong>{`=`}<inlineCode parentName="p">{`key=value`}</inlineCode>{`
The `}<inlineCode parentName="p">{`key=value`}</inlineCode>{` used as a variable in the templates. Use the flag multiple
times to set multiple variables.`}</p>
    <p><strong parentName="p">{`--set-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The JSON `}<inlineCode parentName="p">{`file`}</inlineCode>{` with the template data variables.`}</p>
    <p><strong parentName="p">{`--dry-run`}</strong>{`
Executes the command without changing any file.`}</p>
    <p><strong parentName="p">{`-f`}</strong>{`, `}<strong parentName="p">{`--force`}</strong>{`
Force the overwrite of files without asking.`}</p>
    <p><strong parentName="p">{`--ca-config`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The certificate authority configuration `}<inlineCode parentName="p">{`file`}</inlineCode>{`. Defaults to
$(step path)/config/ca.json`}</p>
    <p><strong parentName="p">{`--ca-url`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
`}<inlineCode parentName="p">{`URI`}</inlineCode>{` of the targeted Step Certificate Authority.`}</p>
    <p><strong parentName="p">{`--root`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the PEM `}<inlineCode parentName="p">{`file`}</inlineCode>{` used as the root certificate authority.`}</p>
    <p><strong parentName="p">{`--offline`}</strong>{`
Creates a certificate without contacting the certificate authority. Offline mode
uses the configuration, certificates, and keys created with `}<strong parentName="p">{`step ca init`}</strong>{`,
but can accept a different configuration file using `}<strong parentName="p">{`--ca-config`}</strong>{` flag.`}</p>
    <p><strong parentName="p">{`--context`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The `}<inlineCode parentName="p">{`name`}</inlineCode>{` of the context for the new authority.`}</p>
    <p><strong parentName="p">{`--profile`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The `}<inlineCode parentName="p">{`name`}</inlineCode>{` that will serve as the profile name for the context.`}</p>
    <p><strong parentName="p">{`--authority`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The `}<inlineCode parentName="p">{`name`}</inlineCode>{` that will serve as the authority name for the context.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Print the public keys used to verify user certificates:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh config --roots
`}</code></pre>
    <p>{`Print the public keys used to verify host certificates:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh config --host --roots
`}</code></pre>
    <p>{`Apply configuration templates on the user system:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh config
`}</code></pre>
    <p>{`Apply configuration templates on a host:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh config --host
`}</code></pre>
    <p>{`Apply configuration templates with custom variables:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh config --set User=joe --set Bastion=bastion.example.com
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      